<template>
	<main>
		<div class="section s4">
			<a-section class="section-wrap">
				<div class="title-box"></div>
				<div class="content-box" :class="[show_more ? 'active' : '']">
					<div class="plan">
						<div class="wrap">
							<div class="item" v-for="(item,index) in plan" :key="index" >
								<div class="info">
									<div class="time">{{ time(item.start_time) }}<i :class="[item.end_time ? '' : 'hidden']">-{{time(item.end_time)}}</i></div>
									<div class="about">
										<div class="time">{{ time(item.start_time) }}<i :class="[item.end_time ? '' : 'hidden']">-{{time(item.end_time)}}</i></div>
										<div class="tit">{{ item.title }}</div>
										<div class="speaker" v-if="item.speaker && item.speaker.length > 0">
											<div class="li" v-for="(v,i) in item.speaker" :key="i"><span v-if="v.username">{{ v.username }}</span><span class="line" v-if="v.position">/</span><span v-if="v.position">{{v.position}}</span></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a-section>
		</div>
	</main>
</template>
<script>
import moment from "moment";

export default {
	props:{
		plan:{
			type:Array,
			default:()=>{
				return []
			}
		}
	},
	data(){
		return {
			show_more:false,
		}
	},
	methods:{
		time(time){
			return moment.unix(time).format('HH:mm')
		},
		showMore(){
			this.show_more = true;
		}
	}
}
</script>
<style lang="scss" scoped>
.s4{padding-top: 60px;position: relative;
	.section-wrap{text-align: center;
		.title-box{display: inline-block; width: 1046px;height:100px; background: url("~@/assets/image/t3.png") no-repeat top center;background-size: contain;}
		.content-box{margin-top: 60px;text-align: left;
			.plan{position: relative;
				.wrap{
					.item{color:#fff;margin-bottom: 3px;
						.info{display: flex;
							.time{width: 274px; font-size: 21px;padding-left: 35px;
								i.hidden{opacity: 0;}
							}
							.about{flex: 1;padding: 10px;background: linear-gradient(to right,rgba(10,52,135,1),rgba(10,52,135,0));
								.time{display:none;}
								.tit{font-size: 18px;}
								.speaker{margin-top: 10px;
									.li{font-size: 16px;margin-bottom: 5px;display: flex;justify-content: left;
										span:nth-child(1){font-weight: bold;}
										span.line{margin: 0 5px;}
									}
									.li:nth-last-child(1){margin-bottom: 0;}
								}
							}
						}
					}
					.item:nth-last-child(1){margin-bottom: 0;}
				}
			}
			.plan::after{content:"";display: block;position: absolute;top:0;left:260px;width: 4px;height:100%; background:#1678e2;}
		}
	}
}
.s4::before{content: "";display: block;position: absolute;top:120px;left:0;width: 149px;height:849px;background: url("~@/assets/image/25.png") no-repeat left top;background-size: contain;pointer-events: none;}
.s4::after{content: "";display: block;position: absolute;bottom:0;right:0;width: 180px;height:34px;background: url("~@/assets/image/27.png") no-repeat left top;background-size: contain;pointer-events: none;}
@media screen and (max-width: 1230px) {
	.s4{padding-top: middle(60px);
		.section-wrap{
			.title-box{width:middle( 1046px);height:middle(100px);}
			.content-box{margin-top: middle(60px);
				.plan{
					.wrap{
						.item{
							.info{
								.time{width: middle(274px); font-size: middle(21px);padding-left: middle(35px);}
								.about{padding: middle(10px);
									.tit{font-size: middle(18px);}
									.speaker{margin-top: middle(10px);
										.li{font-size: middle(16px);margin-bottom: middle(5px);
											span.line{margin:0 middle(5px)}
										}
									}
								}
							}
						}
					}
				}
				.plan::after{left:middle(260px);}
			}
		}
	}
	.s4::before{top:middle(120px);width: middle(149px);height:middle(849px);}
	.s4::after{width: middle(180px);height:middle(34px);}
}
@media screen and (max-width: 992px) {
	.s4{padding-top: 40px;
		.section-wrap{
			.title-box{width: 100%;height:40px;}
			.content-box{margin-top: 12px;
				.plan{
					.wrap{
						.item{
							.info{
								.time{display: none}
								.about{padding: 10px;
									.time{display: block;padding-left: 0;font-weight: bold;margin-bottom: 5px;}
									.tit{font-size: 14px;}
									.speaker{margin-top:5px;
										.li{font-size:12px;margin-bottom:3px;
											span.line{margin: 0 3px;}
											span:nth-child(3){flex: 1;}
										}
									}
								}
							}
						}
					}
				}
				.plan::after{display: none;}
			}
		}
	}
	.s4::before{top:60px;width:75px;height:425px;}
	.s4::after{width:90px;height:17px;}
}
</style>
