<template>
	<main>
		<div class="section s7">
			<a-section class="section-wrap">
				<div class="title-box"></div>
				<div class="content-box">
					<div class="list">
						<div class="item">
							<div class="qrcode">
								<img :src="require('@/assets/image/q1.png')" alt="">
							</div>
							<div class="tit">联想凌拓官方视频号</div>
						</div>
						<div class="item">
							<div class="qrcode">
								<img :src="require('@/assets/image/q2.png')" alt="">
							</div>
							<div class="tit">Bilibili</div>
						</div>
						<div class="item">
							<div class="qrcode">
								<img :src="require('@/assets/image/q3.png')" alt="">
							</div>
							<div class="tit">CIO时代网</div>
						</div>
					</div>
				</div>
			</a-section>
		</div>
	</main>
</template>
<script>
export default {

}
</script>
<style lang="scss" scoped>
.s7{padding-top: 60px;
	.section-wrap{text-align: center;position: relative;z-index: 2;
		.title-box{display: inline-block; width: 1046px;height:100px; background: url("~@/assets/image/t6.png") no-repeat top center;background-size: contain;}
		.content-box{margin-top: 60px;display: flex;justify-content: center;
			.list{display: flex;flex-wrap: wrap;
				.item{width: 170px;margin-right: 100px;padding: 10px;background: #fff;
					.qrcode{
						img{width: 100%;object-fit: contain;display: block;}
					}
					.tit{margin-top: 10px;font-size: 16px;text-align: center;}
				}
				.item:nth-last-child(1){margin-right: 0;}
			}
		}
	}
}
@media screen and (max-width: 1230px) {
	.s7{padding-top: middle(60px);
		.section-wrap{
			.title-box{width: middle(1046px);height:middle(100px);}
			.content-box{margin-top: middle(60px);
				.list{
					.item{width: middle(170px);margin-right: middle(100px);padding: middle(10px);
						.tit{margin-top: middle(10px);font-size:middle(16px);}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 992px) {
	.s7{padding-top: 40px;
		.section-wrap{
			.title-box{width: 100%;height:40px;}
			.content-box{margin-top: 15px;
				.list{
					.item{width: 90px;margin-right: 25px;padding: 5px;
						.tit{margin-top: 5px;font-size: 12px;}
					}
				}
			}
		}
	}
}
</style>
