<template>
	<main>
		<div class="section s2">
			<a-section class="section-wrap">
				<div class="title-box"></div>
				<div class="content-box">
					<div class="content">
						<p>数字经济新时代，云计算、5G、产业互联网、人工智能等新型基础设施领域的发展呈蓬勃态势，新一轮科技革命及产业变革不断加速，数据作为核心生产要素所释放出的价值日益凸显。随着产业数字化转型的快速推进，作为算力价值发挥的基础，数据存储技术不断演进，数据智能管理的性能及效率日益攀升。</p>
						<p>作为智能数据管理及中国企业数字化变革的赋能者，联想凌拓如何助力千行百业激发数据源动力，释放数据潜能，加速推动数字中国建设进程？2022年12月21日，“数矩觉醒  DATA  FABRIC INSIGHT-联想凌拓全新一代存储系列产品发布会”将在线上开启。届时，我们将邀请业界专家、媒体、客户与生态合作伙伴共同参与。聚焦数智融合新时代下数据智能管理的创新话题，展现联想凌拓的创新成果，围绕数据应用生态矩阵，深入洞察数据价值，探讨更多场景下的数据驱动应用。</p>
					</div>
				</div>
			</a-section>
		</div>
	</main>
</template>
<script>
export default {

}
</script>

<style lang="scss" scoped>
.s2{color:#fff;position: relative;
	.section-wrap{text-align: center;position: relative;z-index: 10;
		.title-box{display: inline-block; width: 1046px;height:100px; background: url("~@/assets/image/t1.png") no-repeat top center;background-size: contain;	}
		.content-box{margin-top: 30px;
			.content{background: linear-gradient(to right,rgba(4,17,90,0),rgba(4,17,90,1) 10%,rgba(4,17,90,1) 90%,rgba(4,17,90,0));padding: 50px;font-size: 18px;line-height:40px;text-align: left;
				p{text-indent:40px;}
			}
		}
	}
}
.s2::before{content: "";display: block;position:absolute;top:0;left:0;width: 100px;height:589px;background: url("~@/assets/image/21.png") no-repeat left top;background-size:contain;}
.s2::after{content: "";display: block;position:absolute;top:150px;right:0;width: 170px;height:25px;background: url("~@/assets/image/22.png") no-repeat right top;background-size:auto 25px;}
@media screen and (max-width: 1230px) {
	.s2{
		.section-wrap{
			.title-box{width: middle(1046px);height:middle(100px); padding: middle(60px) 0 middle(5px);}
			.content-box{margin-top: middle(30px);
				.content{padding: middle(50px);font-size: middle(18px);line-height:middle(40px);
					p{text-indent:middle(40px);}
				}
			}
		}
	}
	.s2::before{width:middle( 100px);height:middle(589px);}
	.s2::after{top:middle(150px);width: middle(170px);height:middle(25px);}
}
@media screen and (max-width: 992px) {
	.s2{padding-top: 40px;
		.section-wrap{
			.title-box{width: 100%;height:40px;}
			.content-box{margin-top: 12px;
				.content{padding: 0;font-size: 14px;line-height:1.6;
					p{text-indent: 30px;}
				}
			}
		}
	}
	.s2::before{width:50px;height:293px;}
	.s2::after{top:150px;width:85px;height:13px;}
}
</style>
