<template>
	<main>
		<div class="section s1">
			<div class="bg">
				<img :src="require('@/assets/image/kv_bg.png')" alt="">
			</div>
			<div class="section-wrap">
				<div class="content-box">
					<div class="kv">
						<img class="pc" :src="require('@/assets/image/kv.png')" alt="">
					</div>
<!--					<div class="time-box" v-if="user_info.name && !is_end">-->
<!--						<div class="tit">距离发布会开始</div>-->
<!--						<div class="time">-->
<!--							<div class="text">{{cut_time.day}}天{{cut_time.hour}}小时{{cut_time.min}}分{{cut_time.seconds}}秒</div>-->
<!--						</div>-->
<!--						<div class="tip">敬请期待</div>-->
<!--					</div>-->
					<div class="btn-box" v-if="user_info.name">
						<div class="btn" @click="toLive">
							<div class="text">观看直播</div>
						</div>
					</div>
					<div class="btn-box" v-else>
						<div class="btn" @click="toBook">
							<div class="text">立即参会</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
import moment from "moment";
import {makeNumber} from "@/common/number";
export default {
	data(){
		return {
			is_end:false,
			cut_time:{
				day:0,
				hour:0,
				min:0,
				sec:0,
			}
		}
	},
	computed:{
		user_info(){
			return this.$store.state.views.user_info
		}
	},
	mounted() {
		this.cutTime()
	},
	methods:{
		cutTime(){
			const step = ()=>{
				var end_time = moment('2022-12-21 14:00:00').unix();
				var now_time = moment().unix();
				var offset = end_time - now_time;
				if(offset > 0){
					var day = parseInt(offset / 60 / 60 / 24 , 10)
					var hour = parseInt(offset / 60 / 60 % 24  , 10)
					var min = parseInt(offset / 60 % 60, 10)
					var seconds = parseInt(offset % 60, 10)
					hour = makeNumber(hour,2);
					min = makeNumber(min,2);
					seconds = makeNumber(seconds,2);
				}else{
					this.is_end = true;
				}
				this.cut_time = {
					day:day,
					hour:hour,
					min:min,
					seconds:seconds,
				}
			}
			step();
			clearInterval(this.timer);
			this.timer = setInterval(()=>{
				step()
			},1000)
			this.$once('beforeDestroy',()=>{
				clearInterval(this.timer);
			})
		},
		toLive(){
			this.$emit('toLive')
		},
		toBook(){
			this.$emit('toBook')
		}
	}
}
</script>

<style lang="scss" scoped>
.s1{position: relative;
	.bg{
		img{width: 100%;display: block;}
	}
	.section-wrap{
		.content-box{position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);
			.kv{
				img{width: 1028px;margin: 0 auto;object-fit: contain;object-position: top center; display: block;}
			}
			.time-box{color:#fff;text-align: center;margin-top: -20px;
				.tit,.tip{margin-top: 45px;font-size: 44px;font-weight: bold;text-shadow: 0 0 10px rgba(76,42,173,.6),0 0 20px rgba(76,42,173,.6),0 0 30px rgba(76,42,173,.6),0 0 40px rgba(76,42,173,.6);letter-spacing: 10px;}
				.time{margin-top: 45px;padding: 0 20px; font-size: 75px;border:4px solid #fff;border-radius: 16px;letter-spacing: 5px;display: inline-block;position: relative;
					.text{z-index: 2;position: relative;text-shadow: 0 0 10px rgba(76,42,173,.6),0 0 20px rgba(76,42,173,.6),0 0 30px rgba(76,42,173,.6),0 0 40px rgba(76,42,173,.6);word-break:keep-all;}
				}
				.time::after{content:"";display: block;position: absolute;top:4px;left:4px;width: calc(100% - 8px);height:calc(100% - 8px);backdrop-filter: blur(3px);background: rgba(255,255,255,.2);border-radius: 16px;}
			}
			.btn-box{
				.btn{display: block; width: 220px;height:64px;margin: 0 auto; border-radius: 8px;background:#fff;text-align: center;line-height: 64px;cursor: pointer;
					.text{color:rgba(127, 40, 84, 1);font-size: 24px;font-weight: bold;padding-right: 30px;display: inline-block;background: url("~@/assets/image/6.png") no-repeat right center;background-size: 20px;}
				}
				.btn.btn-2{
					.text{font-size: 20px;}
				}
			}
		}
	}
}
.s1::before{content: "";display: block;position: absolute;bottom:-1px;left:0;width: 100%;height:30%;background: linear-gradient(to bottom,rgba(1,5,70,0),rgba(1,5,70, 1));}
@media screen and (max-width: 1230px) {
	.s1{
		.section-wrap{
			.content-box{
				.kv{
					img{width: middle(1028px);}
				}
				.time-box{margin-top: middle(-20px);
					.tit,.tip{margin-top: middle(45px);font-size: middle(44px);letter-spacing: middle(10px);}
					.time{margin-top: middle(45px);padding: 0 middle(20px); font-size: middle(75px);border:middle(4px) solid #fff;border-radius: middle(16px);letter-spacing: middle(5px);}
					.time::after{top:middle(4px);left:middle(4px);width: calc(100% - 8px * .82);height:calc(100% - 8px * .82);backdrop-filter: blur(3px);background: rgba(255,255,255,.2);border-radius:middle(16px);}
				}
				.btn-box{
					.btn{width:middle( 220px);height:middle(64px);border-radius: middle(8px);line-height: middle(64px);
						.text{font-size: middle(24px);padding-right: middle(30px);background-size: middle(20px);}
					}
					.btn.btn-2{
						.text{font-size:middle( 20px);}
					}
				}
			}
		}
	}
}
@media screen and (max-width: 992px) {
	.s1{
		.section-wrap{
			.content-box{width: calc(100% - 30px);
				.kv{text-align: center;
					img{width:70%;}
				}
				.time-box{margin-top:-5px;
					.tit,.tip{margin-top: 10px;font-size:18px;letter-spacing:2px;}
					.time{margin-top:10px;padding: 0 10px; font-size:22px;border:1px solid #fff;border-radius: 8px;letter-spacing: 2px;}
					.time::after{top:1px;left:1px;width: calc(100% - 2px);height:calc(100% - 2px);backdrop-filter: blur(3px);background: rgba(255,255,255,.2);border-radius:8px;}
				}
				.btn-box{margin-top: 15px;
					.btn{width:110px;height:32px;border-radius:3px;line-height:32px;
						.text{font-size: 12px;padding-right:15px;background-size:10px;}
					}
				}
			}
		}
	}
	.s1::before{display: none;}
}
</style>
